import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { CookieService } from 'ngx-cookie-service';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './services/auth.service';
import { EventService } from './services/event.service';
import { NavigationService } from './services/navigation.service';
import { PwaService } from './services/pwa.service';
import { ChatV1Service } from './services/services-v1/chat-v1.service';
import { EntityV1Service } from './services/services-v1/entity-v1.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'app';
  template: any;
  valueInput: any;
  savedImages: any[] = [];
  color: any = '';
  openPrint: boolean = false;
  propertyToPrint: any;
  entityData: any;
  qrCodeLink: any = 'https://demo.imodigi.com';
  entityDataSubscription!: Subscription;

  constructor(
    private swUpdate: SwUpdate,
    private navigationService: NavigationService,
    private pwaService: PwaService,
    private eventService: EventService,
    private entityV1Service: EntityV1Service,
    private authService: AuthService,
    private chatV1Service: ChatV1Service,
    private cookieService: CookieService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.getEntityData();
    this.swUpdate.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(evt => {
        if (confirm("Há uma nova versão disponível, confirme para atualizar.")) {
          // Reload the page to update to the latest version.
          //this.dbService.deleteDatabase().subscribe();
          // caches.keys().then(keyList => Promise.all(
          //   keyList.map((key) => {
          //     return caches.delete(key);
          //   })
          // ));
          this.cookieService.delete('cookieConsent');
          document.location.reload();
        }
      });

    this.verifyOldUrl();
    // if (this.swUpdate.isEnabled) {
    //   this.entityV1Service.getText().subscribe({
    //     error: (data: any) => {
    //       if (data.error.text) {
    //         const text = data.error.text.split('Debug log:')[0];
    //         if (text.includes('Hash mismatch') && text.includes('/index.php')) {
    //           let split = data.error.text.split('Version');
    //           split = split[0].split('expected ');
    //           const oldHash = split[1].split(',')[0].trim();
    //           const newHash = split[1].split('got')[1].split(' (after cache busting)')[0].trim();
    //           if (oldHash && newHash) {
    //             this.entityV1Service.updateHash(newHash, oldHash).subscribe();
    //           }
    //         }
    //       }
    //     }
    //   });
    // }
    this.checkApp();
    this.navigationService.startHistory();
    this.template = environment.template;
    this.eventService.subscribe('app-saved-images', images => this.savedImages = images);
    this.eventService.subscribe('chat-disconnected', () => this.initChat());
    this.initTemplate();
    this.initChat();
    this.getImtTable();
  }

  getImtTable() {
    const imt = sessionStorage.getItem('imtTable');
    if (!imt) {
      this.entityV1Service.getImtTable().subscribe({
        next: data => {
          sessionStorage.setItem('imtTable', JSON.stringify(data));
        }
      })
    }
  }

  getEntityData() {
    this.entityDataSubscription = this.entityV1Service.getEntityData().subscribe({
      next: data => {
        this.color = data.mainColor;
        localStorage.setItem('entityData', JSON.stringify(data));
        this.entityData = data;
        this.updateColors(data);
      },
      error: err => {
        console.log(err);
        const data: any = localStorage.getItem('entityData');
        this.entityData = JSON.parse(data);
        this.color = JSON.parse(data).mainColor;
        this.updateColors(this.entityData);
      }
    });
  }

  verifyOldUrl() {
    this.route.queryParams.subscribe(param => {
      if (param['c'] && param['p']) {
        let entityId: any = document.getElementById('aux-env-input');
        entityId = entityId.value;
        this.router.navigateByUrl(`/property/shared-property/${entityId}-${param['c']}-${param['p']}`);
      }
    });
  }

  initChat() {
    this.authService.currentUser.subscribe(user => { if (user) this.chatV1Service.openConnection(user) });
  }

  async checkApp() {
    if ('getInstalledRelatedApps' in window.navigator) {
      window.addEventListener('appinstalled', (event) => {
        this.pwaService.setCookie(false);
      });
    }
  }

  updateColors(data: any) {
    if (this.color != '') {
      const mainColor = this.color;
      const secondColor = '#2a2a2a';
      const headerColor = data.headerColor ? data.headerColor : mainColor + 'CC';
      const metaThemeColor = document.querySelector("meta[name=theme-color]");
      metaThemeColor?.setAttribute("content", mainColor);
      document.documentElement.style.setProperty('--main-color', mainColor);
      document.documentElement.style.setProperty('--second-color', secondColor);
      document.documentElement.style.setProperty('--header-color', headerColor);
      if (this.entityData?.bgMobile && this.entityData?.bgMobile == 'header') {
        document.documentElement.style.setProperty('--bg-mobile', headerColor);
        const metaThemeColor1 = document.querySelector("meta[name=theme-color]");
        metaThemeColor1?.setAttribute("content", headerColor);
      } else {
        document.documentElement.style.setProperty('--bg-mobile', mainColor);
      }
    } else {
      const mainColor = environment.mainColor ? environment.mainColor : '#2a2a2a';
      const secondColor = environment.secondColor ? environment.secondColor : '#2a2a2a';
      const headerColor = environment.headerColor ? environment.headerColor : mainColor + 'CC';
      const metaThemeColor = document.querySelector("meta[name=theme-color]");
      metaThemeColor?.setAttribute("content", mainColor);
      document.documentElement.style.setProperty('--main-color', mainColor);
      document.documentElement.style.setProperty('--second-color', secondColor);
      document.documentElement.style.setProperty('--header-color', headerColor);
      if (this.entityData?.bgMobile && this.entityData?.bgMobile == 'header') {
        document.documentElement.style.setProperty('--bg-mobile', headerColor);
        const metaThemeColor1 = document.querySelector("meta[name=theme-color]");
        metaThemeColor1?.setAttribute("content", headerColor);
      } else {
        document.documentElement.style.setProperty('--bg-mobile', mainColor);
      }
    }
    if (this.entityData?.logoSize) {
      document.documentElement.style.setProperty('--logo-size', this.entityData?.logoSize + 'px');
    }
    if (this.entityData?.paddingLogo && this.entityData?.paddingLogo != 0) {
      document.documentElement.style.setProperty('--padding-logo', this.entityData?.paddingLogo + 'px');
    }
    if (this.entityData?.scrollPaddingLogo && this.entityData?.scrollPaddingLogo != 0) {
      document.documentElement.style.setProperty('--scroll-padding-logo', this.entityData?.scrollPaddingLogo + 'px');
    }
  }

  initTemplate() {
    switch (this.template) {
      case 'template1':
        this.initTemplate1();
        break;
      default: this.initTemplate1();
    }

  }
  initTemplate1() {
    document.body.classList.add('homepage-9');
    document.body.classList.add('hp-6');
    document.body.classList.add('homepage-1');
  }

  ngOnDestroy(): void {
    this.chatV1Service.closeConnection();
    this.entityDataSubscription.unsubscribe();
    window.removeEventListener('appinstalled', () => { })
  }
}
