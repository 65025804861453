import { PropertyTypeModel, LocationModel, PropertyModel } from "../models/property.model"

/**
 * Lista de Propriedades
 */
export const PropertiesDetails: PropertyModel[] = [
    {
        title: "Moradia T3+1",
        description: `Moradia T3+1 localizada em Leça da Palmeira com uma área de 162,6 m2 inserida num terreno com 1056 m2.
        Dispõe de 4 quartos sendo 1 en-suite, cozinha equipada, sala com recuperador de calor e uma vista panorâmica sobre a costa sul, 1 casa de banho.
        Ar condicionado. Água e electricidade da rede.
        No exterior existe uma zona de barbecue associado á zona da piscina, com água aquecida através de bomba da calor, zona de arrumos.
        Jardim com árvores de fruto. Magnificas vistas, a zona é muito tranquila e verdejante.
        A aldeia de Marmelete encontra-se a cinco minutos de carro, e a vila de Aljezur apenas a quinze minutos.
        Marque já a sua visita.`,
        reference: "W45236415",
        propertyType: "House",
        energyCertification: 'B',
        yearBuilt: 2015,
        goldenVisa: false,
        price: 300000,
        latitude: "41.1807397",
        longitude: "-8.6827037",
        address: "Rua Conde Alto Mearem",
        zipCode: '4587-965',
        country: "Portugal",
        district: "Porto",
        city: "Matosinhos",
        locationId: 3,
        featured: true,
        businessType: "Sale",
        postOnMap: true,
        postDaysOnMarket: false,
        timeInMarket: "25",
        areas: [
            { name: 'Useful Area', value: 0 },
            { name: 'Gross Area', value: 162 },
            { name: 'Land Area', value: 1056 },
        ],
        characteristics: [
            {
                name: 'Divisions', chars: [
                    'Front Porch',
                    'Private Bathroom',
                    'Openspace'
                ]
            },
            {
                name: 'Equipaments', chars: [
                    'Barbecue'
                ]
            },
            {
                name: 'Services', chars: [
                    'Phone',
                    'Internet'
                ]
            }
        ],
        divisions: [
            { name: 'Living Rooms', value: 2 },
            { name: 'Pools', value: 9 },
            { name: 'Kitchens', value: 6 },
            { name: 'Bathrooms', value: 1 },
            { name: 'Entrances', value: 4 },
            { name: 'Rooms', value: 5 }
        ],
        agent: {
            name: "Marco Silva",
            email: "marcosilva@imodigi.com",
            phone: "+351 000000000",
            profileImg: "assets/images/avatar-9.jpg"
        },
        media: {
            images: [
                "assets/images/your-imo/1-1-100-20181210100143000000-25B63A0F-8A8F-4062-B69D-DAE62F5E9519.jpg",
                "assets/images/your-imo/1-1-100-20181210100159000000-E3CC9E39-1D14-4B3D-A234-E4C139E47E29.jpg",
                "assets/images/your-imo/1-1-100-20181210100226000000-A1733908-CE13-43F4-A224-402EFA813311.jpg",
                "assets/images/your-imo/1-1-101-20181210102600000000-996C71DF-1AF0-44A5-8325-C3EB4C746F2F.jpg",
                "assets/images/your-imo/1-1-101-20181210102601000000-C641BCAD-5564-4CA7-A380-3E7E6014435C.jpg",
                "assets/images/your-imo/1-1-101-20181210102600000000-A058B330-5096-412D-B2B4-025414678EC1.jpg"
            ],
            thumbnail: [
                "assets/images/your-imo/1-1-100-20181210100143000000-25B63A0F-8A8F-4062-B69D-DAE62F5E9519.jpg",
                "assets/images/your-imo/1-1-100-20181210100159000000-E3CC9E39-1D14-4B3D-A234-E4C139E47E29.jpg",
            ]
        },
        otherMultimedia: {
            video: [
                'https://www.youtube.com/embed/wdIh_S_VSTk'
            ]
        }
    },
    {
        title: "Apartamento T3 próximo da Maia",
        description: `Ótimo apartamento para venda na zona de Maia`,
        reference: "D18030011",
        propertyType: "Apartment",
        yearBuilt: 1990,
        goldenVisa: true,
        price: 195000,
        energyCertification: "F",
        postOnMap: true,
        latitude: "41.1783333",
        longitude: "-8.6649367",
        address: "Rua 12, 18",
        zipCode: "4485-308",
        country: "Portugal",
        district: "Porto",
        city: "Vila do Conde",
        locationId: 1,
        featured: true,
        businessType: "Sale",
        timeInMarket: "25",
        areas: [
            { name: 'Useful Area', value: 106 },
            { name: 'Gross Area', value: 119 }
        ],
        divisions: [
            { name: 'Bedrooms', value: 3 },
            { name: 'Kitchens', value: 1 },
            { name: 'Bathrooms', value: 1 }
        ],
        agent: {
            name: "Marco Silva",
            email: "marcosilva@imodigi.com",
            phone: "+351 000000000",
            profileImg: "assets/images/avatar-9.jpg"
        },
        media: {
            images: [
                "assets/images/your-imo/1-1-101-20181210102632000000-9E23AC33-FF7C-4B29-8066-E71180131EF6.jpg",
                "assets/images/your-imo/1-1-102-20181210104918000000-19FED7D2-B4AD-4F8B-AE42-1B3443C753F3.jpg",
                "assets/images/your-imo/1-1-102-20181210104920000000-C3EFC521-9241-4DC9-AC18-10F2F5403839.jpg",
                "assets/images/your-imo/1-1-102-20181210104921000000-64B685B0-8576-4A4C-9BC9-1E64C4D24ECD.jpg",
                "assets/images/your-imo/1-1-102-20181210104922000000-54668E86-FE18-40BD-9697-D67EB8C5203C.jpg",
                // "assets/images/your-imo/1-1-102-20181210104924000000-547A5047-0D77-4F6A-A25B-7C993F906E54.jpg"
            ],
            thumbnail: [
                "assets/images/your-imo/1-1-101-20181210102632000000-9E23AC33-FF7C-4B29-8066-E71180131EF6.jpg",
                "assets/images/your-imo/1-1-102-20181210104918000000-19FED7D2-B4AD-4F8B-AE42-1B3443C753F3.jpg",
            ]
        }
    }
]

export const PropertyTypes: PropertyTypeModel[] = [
    {
        id: 1,
        name: "Apartments"
    },
    {
        id: 2,
        name: "Houses"
    },
    {
        id: 3,
        name: "Offices"
    },
    {
        id: 4,
        name: "Rural Farms"
    }
]

export const Locations: LocationModel[] = [
    {
        id: 1,
        name: "Porto",
        imgUrl: "assets/images/popular-places/porto.jpg"
    },
    {
        id: 2,
        name: "Lisboa",
        imgUrl: "assets/images/popular-places/lisboa.jpg"
    },
    {
        id: 3,
        name: "Aveiro",
        imgUrl: "assets/images/popular-places/aveiro.webp"
    },
    {
        id: 4,
        name: "Algarve",
        imgUrl: "assets/images/popular-places/algarve.jpg"
    }
]