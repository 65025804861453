import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, delay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { User } from '../models/auth.model';

@Injectable({ providedIn: 'root' })
export class AuthService {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  returnUrl: any;
  entityId: number = environment.entityId;

  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')!));
    this.currentUser = this.currentUserSubject.asObservable();
    // this.removeUserToLocalStorage();
  }

  /**
   * current user
   */
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   * Performs the auth
   * @param email email of user
   * @param password password of user
   */
  login(email: string, password: string) {

    const entityId = this.entityId;

    return this.http.post<any>(`${environment.apiUrl}/login`, { email, password, entityId }, { withCredentials: true })
      .pipe(delay(100),
        map(data => {
          if (data.success) {
            localStorage.setItem('toast', 'true');
          }
        })
      );
  }

  register(data: any) {
    data = {
      ...data,
      entityId: this.entityId
    }

    return this.http.post<any>(`${environment.apiUrl}/users/register`, data, { withCredentials: true });
  }



  refreshCookie() {
    return this.http.get<any>(`${environment.apiUrl}/refresh`, { withCredentials: true });
  }

  getUser() {
    return this.http.get<User>(`${environment.apiUrl}/user-auth`, { withCredentials: true });
  }

  saveUserToLocalStorage(user: User) {
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
  }

  removeUserToLocalStorage() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null!);
  }

  /**
   * Logout the user
   */
  logout(page: string) {
    this.removeUserToLocalStorage();
    if (['t-chat', 't-change-password', 'MLS'].includes(page)) {
      this.router.navigate(['/']);
    }
    // location.reload();
    // return this.http.post<any>(`${environment.apiUrl}/logout`, {}, { withCredentials: true })
    //     .pipe(tap(() => this.removeUserToLocalStorage()));

  }
}
