import { UserModel } from "../models/user.model";

export const UserData: UserModel = {
    name: "Victor Silva",
    email: "victorsilva@datadebug.com",
    phone: "+351 960 057 410",
    address: "Rua de Antonio Candido, 26",
    userImg: [
        "assets/images/avatar-9.jpg"
    ],
    role: "AGENT"
}