<div class="menu-mobile">
    <button (click)="openOffCanvas(menuContent)" class="icon-menu button-none">
        <ion-icon src="assets/new-icons/menu-outline.svg"></ion-icon>
    </button>
</div>

<ng-template #menuContent let-offcanvas>
    <div class="offcanvas-header mobile-menu-header">
        <h4 class="offcanvas-title" id="offcanvas-basic-title">Menu</h4>
        <button type="button" id="close-mobile-menu" class="btn-close btn-close-white" aria-label="Close"
            (click)="offcanvas.dismiss('Cross click')"></button>
    </div>
    <div class="offcanvas-body">
        <form>
            <div class="menu-navigation">
                <ul class="menu-items ps-3">
                    <li *ngIf="!authService.currentUserValue" class="menu-item">
                        <a routerLink="/account/login" (click)="offcanvas.dismiss()">
                            <span class="menu-icon">
                                <ion-icon src="assets/new-icons/person-circle-outline.svg"></ion-icon>
                            </span>
                            <span class="menu-text">{{'MENUITEMS.LOGIN-MENU.TEXT' | translate}}</span>
                        </a>
                    </li>
                    <li class="menu-item">
                        <a routerLink="/about-us" (click)="offcanvas.dismiss()">
                            <span class="menu-icon">
                                <ion-icon src="assets/new-icons/people-outline.svg"></ion-icon>
                            </span>
                            <span class="menu-text">{{'MENUITEMS.ABOUT-US.TEXT' | translate}}</span>
                        </a>
                    </li>
                    <li *ngIf="hasEnterprises" class="menu-item">
                        <a routerLink="/enterprises" (click)="offcanvas.dismiss()">
                            <span class="menu-icon">
                                <ion-icon src="assets/new-icons/enterprise.svg"></ion-icon>
                            </span>
                            <span class="menu-text">{{'t-enterprises' | translate}}</span>
                        </a>
                    </li>
                    <li class="menu-item">
                        <a routerLink="/properties" (click)="offcanvas.dismiss()">
                            <span class="menu-icon">
                                <ion-icon src="assets/new-icons/business-outline.svg"></ion-icon>
                            </span>
                            <span class="menu-text">{{'MENUITEMS.PROPERTIES.TEXT' | translate}}</span>
                        </a>
                    </li>
                    <li class="menu-item">
                        <a routerLink="/contacts" (click)="offcanvas.dismiss()">
                            <span class="menu-icon">
                                <ion-icon src="assets/new-icons/call-outline.svg"></ion-icon>
                            </span>
                            <span class="menu-text">{{'MENUITEMS.CONTACTS.TEXT' | translate}}</span>
                        </a>
                    </li>
                    <li class="menu-item">
                        <a routerLink="/sell-properties" (click)="offcanvas.dismiss()">
                            <span class="menu-icon">
                                <ion-icon src="assets/new-icons/pricetags-outline.svg"></ion-icon>
                            </span>
                            <span class="menu-text">{{'MENUITEMS.SELL-PROPERTIES.TEXT' | translate}}</span>
                        </a>
                    </li>
                    <li class="menu-item">
                        <a (click)="openOffCanvas(languageContent)">
                            <span class="menu-icon d-flex align-items-center justify-content-center">
                                <img [src]="language.flag" width="30" alt="user-image" class="rounded" height="18">
                            </span>
                            <span class="menu-text">{{language.countryName}}</span>
                        </a>
                    </li>
                    <li class="menu-item">
                        <a (click)="openOffCanvas(legalContent)">
                            <span class="menu-icon">
                                <ion-icon src="assets/new-icons/legal.svg"></ion-icon>
                            </span>
                            <span class="menu-text">{{'LEGAL-INFO.TEXT' | translate}}</span>
                        </a>
                    </li>
                </ul>
                <div *ngIf="displayMode != 'standalone' && showPrompt" class="install-app px-4 mt-4">
                    <div class="install-text">
                        <span>{{'t-install-app-text' | translate}}</span>
                    </div>
                    <button (click)="installPwa()" class="btn install-app-button">
                        {{'t-install-app-button' | translate}}
                    </button>
                </div>
                <div class="bottom-options-left">
                    <div>
                        <small><a class="imodigi-link" href="https://imodigi.com" target="_blank">Imodigi®</a></small>
                    </div>
                </div>
                <div class="bottom-options">
                    <div class="version-info">
                        <small>{{version}}</small>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #legalContent let-offcanvas>
    <div class="offcanvas-header mobile-menu-header">
        <h4 class="offcanvas-title" id="offcanvas-basic-title">{{'LEGAL-INFO.TEXT' | translate}}</h4>
        <button type="button" class="btn-close btn-close-white" aria-label="Close"
            (click)="offcanvas.dismiss('Cross click')"></button>
    </div>
    <div class="offcanvas-body">
        <form>
            <div class="menu-navigation">
                <ul class="menu-items ps-3">
                    <li class="menu-item">
                        <a routerLink="/terms-conditions" (click)="offcanvas.dismiss(); closeAll()">
                            <span class="menu-icon">
                                <ion-icon src="assets/new-icons/document-text-outline.svg"></ion-icon>
                            </span>
                            <span class="menu-text">{{'TERMS-CONDITIONS.TEXT' | translate}}</span>
                        </a>
                    </li>
                    <li class="menu-item">
                        <a routerLink="/privacy-policy" (click)="offcanvas.dismiss(); closeAll()">
                            <span class="menu-icon">
                                <ion-icon src="assets/new-icons/document-lock-outline.svg"></ion-icon>
                            </span>
                            <span class="menu-text">{{'PRIVACY-POLICY.TEXT' | translate}}</span>
                        </a>
                    </li>
                    <li class="menu-item">
                        <a routerLink="/alternative-resolution" (click)="offcanvas.dismiss(); closeAll()">
                            <span class="menu-icon">
                                <ion-icon src="assets/new-icons/reader-outline.svg"></ion-icon>
                            </span>
                            <span class="menu-text">{{'t-alternative-resolution' | translate}}</span>
                        </a>
                    </li>
                    <li class="menu-item">
                        <a routerLink="/manage-data" (click)="offcanvas.dismiss(); closeAll()">
                            <span class="menu-icon">
                                <ion-icon src="assets/new-icons/lock-closed-outline.svg"></ion-icon>
                            </span>
                            <span class="menu-text">{{'MANAGE-DATA.TEXT' | translate}}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #languageContent let-offcanvas>
    <div class="offcanvas-header mobile-menu-header">
        <h4 class="offcanvas-title" id="offcanvas-basic-title">{{'t-select-language' | translate}}</h4>
        <button type="button" class="btn-close btn-close-white" aria-label="Close"
            (click)="offcanvas.dismiss('Cross click')"></button>
    </div>
    <div class="offcanvas-body">
        <form>
            <div class="menu-navigation">
                <ul class="menu-items ps-3">
                    <li *ngFor="let item of language.listLang" class="menu-item">
                        <a 
                            (click)="setLanguage(item.text, item.lang, item.flag); offcanvas.dismiss()">
                            <span class="menu-icon d-flex align-items-center justify-content-center">
                                <img src="{{item.flag}}" alt="user-image" width="30" class="rounded" height="18">
                            </span>
                            <span class="menu-text">{{item.text}}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </form>
    </div>
</ng-template>