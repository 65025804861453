import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { map, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LanguageService } from '../languages.service';

@Injectable({
    providedIn: 'root'
})
export class DataminerV1Service {

    idElement: any = document.getElementById('aux-env-input');

    constructor(
        private languageService: LanguageService,
        private http: HttpClient,
        private cookieService: CookieService
    ) { }

    sendData(dataminer: any) {
        if (this.cookieService.check('cookieConsent')) {
            const consent = JSON.parse(this.cookieService.get('cookieConsent'));
            if (consent?.performance) {
                let data: any = {};
                const date = new Date();
                const device = localStorage.getItem('dSession') ? localStorage.getItem('dSession') : this.generateDeviceId();
                const latlng: any = localStorage.getItem('latlng') ? localStorage.getItem('latlng') : 0;
                let latitude = 0;
                let longitude = 0;
                let isGPSOn = 0;
                if (latlng !== 0) {
                    latitude = JSON.parse(latlng).lat;
                    longitude = JSON.parse(latlng).lng;
                    isGPSOn = 1;
                }
                data.action = 'insert';
                let entityId = this.idElement.value;
                data.data = {
                    isWebApp: 1,
                    deviceId: device,
                    deviceSerialNumber: device,
                    entity: entityId,
                    screen: dataminer.screen,
                    buttonClicked: dataminer.buttonClicked,
                    buttonValue1: dataminer.buttonValue1 ? dataminer.buttonValue1 : dataminer.client,
                    buttonValue2: dataminer.buttonValue2 ? dataminer.buttonValue2 : dataminer.property,
                    latitude: latitude,
                    longitude: longitude,
                    isGPSOn: isGPSOn,
                    language: this.languageService.currentLang,
                    countryName: 'Portugal',
                    countryShortName: 'PT',
                    timestamp: date.toLocaleDateString('fr-CA') + ' ' + date.toLocaleTimeString('pt-PT')
                }
                let formData = new FormData();
                formData.append('data', JSON.stringify(data));
                return this.http.post<any>(`${environment.v1Api}/dataminer.php`, formData);
            } else {
                return of('');
            }
        } else {
            return of('');
        }
    }

    generateDeviceId() {
        const device = Math.floor(Math.random() * 1000000000000000) + Date.now();
        localStorage.setItem('dSession', JSON.stringify(device));
        return device;
    }

}