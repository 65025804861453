import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { environment } from "src/environments/environment";
import { EntityAppDetails } from "../data/entity-app.data";
import { UserData } from "../data/user.data";
import { EntityAppModel } from "../models/entity-app.model";

@Injectable({
    providedIn: 'root'
})
export class EntityService {
    entityData = EntityAppDetails;
    userData = UserData;
    constructor(private http: HttpClient) { }

    getEntityData() {
        // const entityId = environment.entityId;
        // return this.http.get<EntityAppModel>(`${environment.apiUrl}/entities/${entityId}`, { withCredentials: true });
        return of(this.entityData);
    }

    getEntityLogo() {
        return of(this.entityData.logo);
    }

    getUserData() {
        return of(this.userData);
    }

    getAgentData() {
        const params = new HttpParams()
            .set('entityId', environment.entityId)

        return this.http.get<any>(`${environment.apiUrl}/entities/agent-data`, { params, withCredentials: true })
    }
}