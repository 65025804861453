import { EntityAppModel } from "../models/entity-app.model";

export const EntityAppDetails: EntityAppModel = {
    name: "Your Imo",
    logo: "assets/images/logo-yi.png",
    address: "Av. da República 885 2ºandar C11, 4450-243 Matosinhos",
    phone: "+351 22 014 7911",
    email: "suporte@imodigi.com"
}

