import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { OnlineStatusService } from 'ngx-online-status';
import { debounceTime, distinctUntilChanged, fromEvent, map, Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { EventService } from 'src/app/services/event.service';
import { LanguageService } from 'src/app/services/languages.service';
import { LoadingService } from 'src/app/services/loading.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ChatV1Service } from 'src/app/services/services-v1/chat-v1.service';

declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  entityData: any;
  languagesSubscription!: Subscription;
  user!: any;
  mobile: boolean = false;
  lastWidth!: number;
  pageTitle: string = '';
  userDrop: boolean = false;
  languageDrop: boolean = false;
  showCreditSim: boolean = false;
  tabletMode: boolean = false;
  cookieValue: any;
  listLang: any = [];
  flagvalue: any;
  flag: any;
  valueset: any;
  countryName: any;
  inputSearch = new FormControl;
  sendInputValue: boolean = true;
  latestSearches: any[] = [];
  clearSearch: {
    isClear: boolean,
    result: any
  } = {
      isClear: false,
      result: {}
    };

  resizeObservable$!: Observable<Event>;
  resizeSubscription$!: Subscription;
  blackHeader: boolean = false;
  pagesToChangeHeader = [
    '',
    't-sell-properties',
    't-about-us',
    't-sign-in',
    't-change-password',
    't-register'
  ];
  logoType = 'rounded';
  logoBlack: any;
  chatMessages: number = 0;
  isOnline: number = 1;


  constructor(
    public loadingService: LoadingService,
    public navigationService: NavigationService,
    public authService: AuthService,
    public translate: TranslateService,
    public languageService: LanguageService,
    private cookiesService: CookieService,
    private eventService: EventService,
    private chatV1Service: ChatV1Service,
    private modalService: NgbModal,
    private dbService: NgxIndexedDBService,
    private router: Router,
    private onlineService: OnlineStatusService
  ) { }


  ngOnInit(): void {
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.pipe(debounceTime(100)).subscribe((evt: any) => {
      if (evt?.isTrusted) {
        this.showOrHidePageTitle(evt.target);
      } else {
        this.showOrHidePageTitle();
      }
    });

    this.isOnline = this.onlineService.getStatus();
    this.onlineService.status.subscribe(status => this.isOnline = status);

    this.inputFilter();

    this.languagesSubscription = this.languageService.getLanguages().subscribe(data => {
      this.listLang = data;
      this.initFlag();
    });

    this.authService.currentUser.subscribe(user => this.user = user);

    // this.loadingService.setLoading(true);

    const entityData: any = localStorage.getItem('entityData');
    this.entityData = JSON.parse(entityData);
    this.logoBlack = this.entityData.otherLogo ? this.entityData.otherLogo : null;
    this.logoType = this.entityData.logoType;

    this.showOrHidePageTitle();
    this.eventService.subscribe('property-price-header', price => {
      if (price > 0 && this.entityData.creditSimulator === 1) {
        this.showCreditSim = true;
      } else {
        this.showCreditSim = false;
      }
    });
    this.eventService.subscribe('header-page-title', title => {
      if (this.pagesToChangeHeader.includes(title)) {
        this.blackHeader = false;
      } else {
        this.blackHeader = true;
      }
      if (title != 't-properties') {
        this.inputSearch.setValue('');
      }
      if (title === 't-chat') {
        this.chatMessages = 0;
      }
      if (this.pageTitle == 'MLS' && title == 't-properties') {
        this.inputSearch.setValue('');
      }
      this.pageTitle = title;
    });
    this.eventService.subscribe('search-to-header', value => {
      this.sendInputValue = false;
      this.inputSearch.setValue(value);
    });
    this.eventService.subscribe('language-change', () => this.initFlag());
    this.eventService.subscribe('latest-searches-update', () => this.getLatestSearches());
    this.eventService.subscribe('stopEventScroll', () => window.removeEventListener('scroll', this.onScroll, true));
    this.eventService.subscribe('restartEventScroll', () => window.addEventListener('scroll', this.onScroll, true));
    this.eventService.subscribe('h-new-chat-message', () => this.chatMessages++)

    window.addEventListener('scroll', this.onScroll, true);

    this.getLatestSearches();
  }

  openModal(content: any, size: any, classModal: any) {
    this.modalService.open(content, { backdrop: true, size: size, windowClass: classModal })
  }

  getLatestSearches() {
    this.dbService.getAll('latestSearches').subscribe({
      next: (latest: any[]) => {
        if (latest?.length > 0) {
          this.latestSearches = latest;
        }
      },
      error: (err: string) => {
        if (err.startsWith('objectStore does not exists')) {
          this.dbService.createObjectStore({
            store: 'latestSearches', storeConfig: { keyPath: 'searchKey', autoIncrement: false },
            storeSchema: []
          });
        }
      }
    })
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  inputFilter() {
    this.inputSearch.valueChanges
      .pipe(
        debounceTime(600),
        distinctUntilChanged(),
        map(value => {
          if (this.sendInputValue) {
            this.eventService.broadcast('header-search-properties', value.toLowerCase());
          } else {
            this.sendInputValue = true;
          }
        })
      ).subscribe();
  }

  onScroll(event: any) {
    var headerOffset = $("#header-container").height() / 2;
    if (event?.target?.scrollingElement?.scrollLeft === 0) {
      if (event.target.scrollingElement.scrollTop >= headerOffset) {
        $("#header").addClass('scroll-menu');
      } else {
        $("#header").removeClass("scroll-menu");
      }
    }
  }

  /***
  * Language Value Set
  */
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = lang;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  initFlag() {
    this.cookieValue = this.cookiesService.get("lang");
    document.querySelector('html')?.setAttribute('lang', this.cookieValue);
    const val = this.listLang.filter((x: any) => {
      return x.lang === this.cookieValue
    });
    this.countryName = val.map((element: any) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = "EN";
      }
    } else {
      this.flagvalue = val.map((element: any) => element.lang);
      this.flag = val.map((element: any) => element.flag);
    }
  }

  showOrHidePageTitle(event: any = null) {
    let width: any;
    let orientation: any;

    if (event === null) {
      width = window.innerWidth;
      orientation = 0;
    } else {
      width = event.outerWidth;
      orientation = event?.screen?.orientation?.angle;
    };

    if (width !== this.lastWidth) {
      this.mobile = width > 480 ? false : true;
      if (width > 480 && width < 1024 || orientation > 0) {
        this.tabletMode = true;
      } else {
        this.tabletMode = false;
      }
    }
  }

  logoutUser() {
    this.chatV1Service.closeConnection();
    this.authService.logout(this.pageTitle);
  }

  openCreditSimulator() {
    this.eventService.broadcast('credit-simulator', true);
  }

  searchWithLatest(search: any, type: 'location' | 'freeSearch') {
    if (!this.clearSearch.isClear) {
      this.closeModal();
      type === 'freeSearch' ? this.router.navigate(['/properties'], { queryParams: { freeSearch: search, lastSearch: true } }) : this.router.navigate(['/properties'], { queryParams: { location: search, lastSearch: true } });
    } else {
      this.clearLastSearch(this.clearSearch.result);
    }
  }

  clearLastSearch(key: any) {
    this.dbService.deleteByKey('latestSearches', key).subscribe(
      {
        error: (err: string) => {
          if (err.startsWith('objectStore does not exists')) {
            this.dbService.createObjectStore({
              store: 'latestSearches', storeConfig: { keyPath: 'searchKey', autoIncrement: false },
              storeSchema: []
            });
          }
        }
      }
    );
    this.latestSearches = this.latestSearches.filter(el => el.searchKey !== key);
    this.clearSearch.isClear = false;
  }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.onScroll, true);
    this.resizeSubscription$.unsubscribe();
    this.languagesSubscription.unsubscribe();
  }

}
