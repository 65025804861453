import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LanguageService } from '../languages.service';

@Injectable({
  providedIn: 'root'
})
export class EntityV1Service {

  pathToImg = "https://imodigi.com/backoffice/";
  idElement: any = document.getElementById('aux-env-input');
  // pathLocal = "http://localhost/imodigi/backoffice/"

  constructor(private http: HttpClient, private languageService: LanguageService) { }

  getEntityData() {
    let entityId = this.idElement.value;
    let data: any = {};
    data.entity = entityId;
    data.language = this.languageService.currentLang;
    let formData = new FormData();
    formData.append('data', JSON.stringify(data));

    return this.http.post<any>(`${environment.v1Api}/get-about-us.php`, formData).pipe(
      map(data => {
        let slideImages: any = [];
        let footerImages: any = [];
        if (data?.aboutUs?.slideImages) {
          let images: [] | null = JSON.parse(data.aboutUs.slideImages);
          if (images) {
            images.forEach(img => slideImages.push(this.pathToImg + img));
          }
        }
        if (data?.aboutUs?.footerImages) {
          let images: [] | null = JSON.parse(data.aboutUs.footerImages);
          if (images) {
            images.forEach(img => footerImages.push(this.pathToImg + img));
          }
        }
        return data = {
          ...data.aboutUs,
          agents: data.agents,
          logo: this.pathToImg + data.aboutUs.logo,
          slideImages: slideImages,
          footerImages: footerImages,
          aboutImg: data.aboutUs.aboutImg != '' ? this.pathToImg + data.aboutUs.aboutImg : null,
          hasEnterprises: data.hasEnterprises,
          hasChat: data.aboutUs.hasChat === 1 ? true : false,
          isDemo: data.aboutUs.isDemo === 1 ? true : false
        }
      })
    );
  }

  getImtTable() {
    let data: any = {};
    let formData = new FormData();
    formData.append('data', JSON.stringify(data));

    return this.http.post<any>(`${environment.v1Api}/get-imt.php`, formData);
  }

  getText() {
    return this.http.get('ngsw/state');
  }

  updateHash(newHash: any, oldHash: any) {
    let data: any = {
      newHash: newHash,
      oldHash: oldHash
    };
    let formData = new FormData();
    formData.append('data', JSON.stringify(data));
    return this.http.post('update-hash.php', formData).pipe(tap((data: any) => {
      if (data.state == 'success') {
        console.log('hash updated');
        const path = location.pathname.split('/');
        let formData1 = new FormData();
        formData1.set('action', 'hashUpdated');
        formData1.set('path', path[1]);

        this.http.post(`${environment.v1Api}/update-app.php`, formData1).subscribe();
      }
    }));
  }

}
