import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LanguageService } from '../languages.service';

@Injectable({
  providedIn: 'root'
})
export class AuthV1Service {

  idElement: any = document.getElementById('aux-env-input');

  constructor(private http: HttpClient, private languageService: LanguageService) { }

  login(email: string, password: string) {
    let entityId = this.idElement.value;
    let data: any = {};
    data.action = 'logIn';
    data.entity = entityId;
    data.username = email;
    data.password = password;
    let formData = new FormData();
    formData.append('data', JSON.stringify(data));

    return this.http.post<any>(`${environment.v1Api}/account.php`, formData).pipe(
      map((data: any) => {
        data.photo = data.photo && data.photo.startsWith('http') ? data.photo : 'assets/images/user/default-user.jpg';
        return data;
      })
    );
  }

  register(registerData: any) {
    let entityId = this.idElement.value;
    let data: any = {};
    data.action = 'signUpV2';
    data.entity = entityId;
    data.username = registerData.email;
    data.password = registerData.password;
    data.name = registerData.name;
    let formData = new FormData();
    formData.append('data', JSON.stringify(data));

    return this.http.post<any>(`${environment.v1Api}/account.php`, formData);
  }

  validateRegisterCode(email: any, code: any) {
    let entityId = this.idElement.value;
    let data: any = {};
    data.action = 'validateCode';
    data.entity = entityId;
    data.username = email;
    data.code = code;
    let formData = new FormData();
    formData.append('data', JSON.stringify(data));

    return this.http.post<any>(`${environment.v1Api}/account.php`, formData);
  }

  sendCodeToChangePass(email: any) {
    let entityId = this.idElement.value;
    let data: any = {};
    data.action = 'requestCode';
    data.entity = entityId;
    data.username = email;
    let formData = new FormData();
    formData.append('data', JSON.stringify(data));

    return this.http.post<any>(`${environment.v1Api}/account.php`, formData);
  }

  changePassword(email: any, password: any, code: any) {
    let entityId = this.idElement.value;
    let data: any = {};
    data.action = 'changePassword';
    data.entity = entityId;
    data.username = email;
    data.password = password;
    data.code = code;
    let formData = new FormData();
    formData.append('data', JSON.stringify(data));

    return this.http.post<any>(`${environment.v1Api}/account.php`, formData);
  }

  sendAccountInfo(uuid: any) {
    let data: any = {};
    data.action = 'requestAccountInfo';
    data.uuid = uuid;
    data.language = this.languageService.currentLang;
    let formData = new FormData();
    formData.append('data', JSON.stringify(data));

    return this.http.post<any>(`${environment.v1Api}/account.php`, formData);
  }

  sendCodeToDelete(uuid: any) {
    let data: any = {};
    data.action = 'requestCodeToDelete';
    data.uuid = uuid;
    let formData = new FormData();
    formData.append('data', JSON.stringify(data));

    return this.http.post<any>(`${environment.v1Api}/account.php`, formData);
  }

  deleteAccount(uuid: any, code: any) {
    let data: any = {};
    data.action = 'deleteAccount';
    data.uuid = uuid;
    data.code = code;
    let formData = new FormData();
    formData.append('data', JSON.stringify(data));

    return this.http.post<any>(`${environment.v1Api}/account.php`, formData);
  }

}
