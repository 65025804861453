<router-outlet *ngIf="entityData"></router-outlet>
<app-property-print [property]="propertyToPrint" [qrCodeLink]="qrCodeLink"
    [entityData]="entityData"></app-property-print>
<img *ngFor="let img of savedImages" id="saved-img" [src]="img" style="display:none">
<div style="display: none" class="d-none">
    <ion-icon src="assets/new-icons/arrow-back-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/add-circle-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/bed-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/briefcase-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/business.svg"></ion-icon>
    <ion-icon src="assets/new-icons/business-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/call-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/cash-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/chatbubble-ellipses-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/chatbubble-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/chevron-down-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/close-circle-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/close-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/cloud-offline-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/construct-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/document-lock-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/document-text-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/heart.svg"></ion-icon>
    <ion-icon src="assets/new-icons/heart-dislike-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/heart-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/history.svg"></ion-icon>
    <ion-icon src="assets/new-icons/home-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/legal.svg"></ion-icon>
    <ion-icon src="assets/new-icons/location-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/lock-closed-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/log-out-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/mail-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/mail-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/mls.svg"></ion-icon>
    <ion-icon src="assets/new-icons/nearby.svg"></ion-icon>
    <ion-icon src="assets/new-icons/operator.svg"></ion-icon>
    <ion-icon src="assets/new-icons/options-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/people.svg"></ion-icon>
    <ion-icon src="assets/new-icons/people-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/person-circle-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/pricetags-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/print-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/reader-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/remove-circle-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/repeat-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/resize-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/search-circle-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/search-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/send-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/share-social-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/stove.svg"></ion-icon>
    <ion-icon src="assets/new-icons/swap-vertical-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/time-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/trash-outline.svg"></ion-icon>
    <ion-icon src="assets/new-icons/sofa.svg"></ion-icon>
    <ion-icon src="assets/new-icons/pool.svg"></ion-icon>
    <ion-icon src="assets/new-icons/enter.svg"></ion-icon>
    <ion-icon src="assets/new-icons/office.svg"></ion-icon>
    <ion-icon src="assets/new-icons/add-outline.svg"></ion-icon>
</div>