import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { EventService } from 'src/app/services/event.service';
import { DataminerV1Service } from 'src/app/services/services-v1/dataminer-v1.service';

@Component({
  selector: 'app-mobile-nav-menu',
  templateUrl: './mobile-nav-menu.component.html',
  styleUrls: ['./mobile-nav-menu.component.css']
})
export class MobileNavMenuComponent implements OnInit {

  currentPage: string = '';
  pagesNone = [
    '/chat',
    '/agent-area'
  ];
  isPropertyPage: boolean = false;
  buttonOpen: boolean = false;

  constructor(
    private router: Router,
    private eventService: EventService,
    private dataminerV1Service: DataminerV1Service,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    let filter = this.pagesNone.filter(page => {
      return this.router.url.split('?')[0].indexOf(page) > -1;
    });
    if (filter.length === 0) {
      if (this.router.url.split('?').length > 1 && this.router.url.split('?')[1].indexOf('latlng') > -1) {
        this.currentPage = '/nearby';
      } else {
        this.currentPage = this.router.url.split('?')[0]
      }
    } else {
      this.currentPage = '';
    }
    // this.currentPage = filter.length > 0 ? '' : this.router.url.split('?')[0];
    if (this.currentPage.indexOf('property/') > -1 || this.currentPage.indexOf('/enterprises/') > -1) {
      this.isPropertyPage = true;
    } else {
      this.isPropertyPage = false;
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        let filter = this.pagesNone.filter(page => {
          return event.urlAfterRedirects.split('?')[0].indexOf(page) > -1;
        });
        if (filter.length === 0) {
          if (this.router.url.split('?').length > 1 && this.router.url.split('?')[1].indexOf('latlng') > -1) {
            this.currentPage = '/nearby';
          } else {
            this.currentPage = this.router.url.split('?')[0]
          }
        } else {
          this.currentPage = '';
        }
        // this.currentPage = filter.length > 0 ? '' : this.router.url.split('?')[0];
        if (this.currentPage.indexOf('property/') > -1 || this.currentPage.indexOf('/enterprises/') > -1) {
          this.isPropertyPage = true;
        } else {
          this.isPropertyPage = false;
          this.buttonOpen = false;
        }
      }
    })
  }

  clickButton() {
    this.buttonOpen = !this.buttonOpen;
    this.eventService.broadcast('toggle-property-float');
  }

  getLocation() {
    this.router.navigate(['/properties']);
    let latlng = localStorage.getItem('latlng');
    if (latlng) {
      this.router.navigate(['/properties'], { queryParams: { latlng: true } });
    } else {
      navigator.geolocation.getCurrentPosition(position => {
        this.watchPosition();
        const newLatLng = { lat: position.coords.latitude, lng: position.coords.longitude };
        localStorage.setItem('latlng', JSON.stringify(newLatLng));
        this.router.navigate(['/properties'], { queryParams: { latlng: true } });
      }, () => {

        this.router.navigate(['/properties']);
      }, { timeout: 10000 });
    }
  }

  watchPosition() {
    const interval = setInterval(() => {
      if (this.cookieService.check('cookieConsent')) {
        navigator.geolocation.watchPosition(position => {
          const newLatLng = { lat: position.coords.latitude, lng: position.coords.longitude };
          localStorage.setItem('latlng', JSON.stringify(newLatLng));
          this.eventService.broadcast('new-location');
          const data = {
            screen: 'automatic',
            buttonClicked: '',
            client: '',
            property: ''
          }
          this.dataminerV1Service.sendData(data).subscribe();
        });
        clearInterval(interval);
      };
    });
  }

}
