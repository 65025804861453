import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeadsV1Service {

  idElement: any = document.getElementById('aux-env-input');

  constructor(private http: HttpClient) { }

  sendMessageForm(dataMessage: any) {
    let entityId = this.idElement.value;

    let id = dataMessage.id.split('P');
    id = id[id.length - 1];
    let client = dataMessage.id.split('C');
    client = client[client.length - 1].split('P');
    client = client[0];
    let data: any = {};
    data.client = client;
    data.entity = entityId;
    data.message = dataMessage.phone && dataMessage.phone != '' ? dataMessage.phone + ' / ' + dataMessage.message : dataMessage.message;
    data.email = dataMessage.email;
    data.county = dataMessage.county ? dataMessage.county : "";
    data.parish = dataMessage.parish ? dataMessage.parish : "";
    data.property = id;
    data.reference = dataMessage.reference;
    data.title = dataMessage.title;
    let formData = new FormData();
    formData.append('data', JSON.stringify(data));

    return this.http.post(`${environment.v1Api}/send-form.php`, formData);
  }

  sendSellForm(form: any) {
    let entityId = this.idElement.value;
    let data: any = {};
    data.entity = entityId;
    data.email = form.email;
    data.district = form.location;
    data.propertyType = form.propertyType;
    data.cellphoneNumber = form.phone;
    data.description = form.message;
    let formData = new FormData();
    formData.append('data', JSON.stringify(data));

    return this.http.post(`${environment.v1Api}/send-sell.php`, formData);
  }

  sendContactForm(form: any) {
    let entityId = this.idElement.value;
    let data: any = {};
    data.client = 0;
    data.entity = entityId;
    data.message = form.name + ' / ' + form.phone + ' / ' + form.message;
    data.email = form.email;
    data.property = 0;
    data.source = "WEBSITE";
    let formData = new FormData();
    formData.append('data', JSON.stringify(data));

    return this.http.post(`${environment.v1Api}/add-lead.php`, formData);
  }

  sendPrivateDeals(form: any) {
    let entityId = this.idElement.value;
    let data: any = {};
    data.client = 0;
    data.entity = entityId;
    data.email = form.email;
    data.message = form.message;
    data.property = 0;
    data.source = "WEBSITE";

    let formData = new FormData();
    formData.append('data', JSON.stringify(data));

    return this.http.post(`${environment.v1Api}/add-lead-private.php`, formData);
  }

  sendCall(propertyId: any) {
    let entityId = this.idElement.value;
    let id = propertyId.split('P');
    id = id[id.length - 1];
    let client = propertyId.split('C');
    client = client[client.length - 1].split('P');
    client = client[0];
    let data: any = {};
    data.client = client;
    data.entity = entityId;
    data.message = "protected";
    data.email = "";
    data.property = id;
    data.source = "CELL";
    let formData = new FormData();
    formData.append('data', JSON.stringify(data));

    return this.http.post(`${environment.v1Api}/add-lead.php`, formData);
  }

  sendNewsletterSubscription(email: any) {
    let entityId = this.idElement.value;
    let data: any = {};
    data.entity = entityId;
    data.email = email;
    let formData = new FormData();
    formData.append('data', JSON.stringify(data));

    return this.http.post(`${environment.v1Api}/add-newsletter.php`, formData);
  }

}
