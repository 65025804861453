import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { BehaviorSubject } from 'rxjs';
import { first, tap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EventService } from './event.service';

@Injectable({ providedIn: 'root' })
export class LanguageService {

  public currentLanguage: BehaviorSubject<any> = new BehaviorSubject<any>('');
  public languages: string[] = ['en', 'es', 'de', 'it', 'pt', 'fr'];
  public showMenu: boolean = false;
  idElement: any = document.getElementById('aux-env-input');

  constructor(
    public translate: TranslateService,
    private cookieService: CookieService,
    private http: HttpClient,
    private dbService: NgxIndexedDBService,
    private eventService: EventService
  ) {

    let browserLang: any;
    /***
     * cookie Language Get
    */
    this.translate.addLangs(this.languages);
    if (this.cookieService.check('lang')) {
      browserLang = this.cookieService.get('lang');
    }
    else {
      browserLang = translate.getBrowserLang();
    }
    this.setLanguage(browserLang.match(/en|es|de|it|pt|fr/) ? browserLang : 'en');
  }

  public get currentLang() {
    return this.currentLanguage.value;
  }

  /***
   * Cookie Language set
   */
  public setLanguage(lang: any) {
    this.currentLanguage.next(lang);
    this.translate.use(lang).subscribe(() => {
      this.showMenu = true;
      this.cookieService.set('lang', lang);
      this.eventService.broadcast('language-change');
      this.translate.getTranslation(lang).subscribe(translation => {
        translation = {
          ...translation,
          lang: lang
        }
        this.dbService.deleteByKey('translations', lang).subscribe({
          next: () => {
            this.dbService.add('translations', translation).subscribe(
              {
                error: (err: string) => {
                  if (err.startsWith('objectStore does not exists')) {
                    this.dbService.createObjectStore({
                      store: 'translations', storeConfig: { keyPath: 'lang', autoIncrement: false },
                      storeSchema: []
                    });
                  }
                }
              }
            );
          },
          error: (err: string) => {
            if (err.startsWith('objectStore does not exists')) {
              this.dbService.createObjectStore({
                store: 'translations', storeConfig: { keyPath: 'lang', autoIncrement: false },
                storeSchema: []
              });
            }
          }
        })
      });
    },
      err => {
        this.dbService.getByKey('translations', lang).pipe(
          tap((translations: any) => {
            if (translations) {
              this.translate.setTranslation(lang, translations);
              this.translate.use(lang);
              this.showMenu = true;
              this.cookieService.set('lang', lang);
            } else {
              console.log('lang error', err);
            }
          })
        ).subscribe(
          {
            error: (err: string) => {
              if (err.startsWith('objectStore does not exists')) {
                this.dbService.createObjectStore({
                  store: 'translations', storeConfig: { keyPath: 'lang', autoIncrement: false },
                  storeSchema: []
                });
              }
            }
          }
        );
      });
  }

  public getLanguages() {
    let entityId = this.idElement.value;

    let data: any = {};
    data.action = 'getLanguages';
    data.entity = entityId;
    data.language = '';
    data.payload = {};
    data.text = '';
    let formData = new FormData();
    formData.append('data', JSON.stringify(data));
    return this.http.post<any>(`${environment.v1Api}/get-labels.php`, formData).pipe(first(), map(langs => {
      return langs.map((lang: any) => {
        return {
          text: lang.designacao,
          id: lang.id,
          lang: lang.friendly_url,
          flag: `assets/images/flags/${lang.friendly_url}.svg`
        }
      })
      localStorage.setItem('langs', JSON.stringify(langs))
    }));

  }

}
