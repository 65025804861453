import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { delay, tap } from 'rxjs';
import { ChatModel } from 'src/app/template1/pages/chat/models/chat.model';
import { environment } from 'src/environments/environment';
import { EventService } from '../event.service';
import { LanguageService } from '../languages.service';

@Injectable({
  providedIn: 'root'
})
export class ChatV1Service {

  connection!: WebSocket;
  connectionUrl = environment.wsUrl;
  connectionTries = 0;
  chatActive: any;
  chatPack: ChatModel = {
    action: "PING",
    writer: "",
    writerName: "",
    writerPhoto: "",
    writerEmail: "",
    writerIsAgent: "",
    writerIsMaster: "",
    writerIsActive: "",
    chat: "",
    client: "",
    property: "",
    propertyReference: "",
    propertyName: "",
    reader: "",
    readerName: "",
    readerPhoto: "",
    readerEmail: "",
    readerIsAgent: "",
    readerIsMaster: "",
    readerIsActive: "",
    entity: 0,
    timestamp: "",
    fileData: "",
    fileName: "",
    fileType: "",
    isInStealthMode: 0,
    isWaitingMessageActive: 0,
    message: "",
    waitingMessage: ""
  };
  lastId!: number;
  public activeUser: any[] = [];
  idElement: any = document.getElementById('aux-env-input');

  constructor(
    private http: HttpClient,
    private languageService: LanguageService,
    private eventService: EventService
  ) {
    this.chatPack.entity = this.idElement.value;
  }

  openConnection(user: any) {
    let entityId = this.idElement.value;

    this.clearChatPack();
    this.initWriterChatPackage(user);
    const clearTries = () => { this.connectionTries = 7 };
    const chatStatus = () => { this.eventService.broadcast('chat-connecting', false) }
    const interval = setInterval(() => {
      if (this.connectionTries < 6) {
        const chat = this.chatPack;
        chat.entity = entityId + "";
        this.connectionTries++;
        if (this.connection === undefined || this.connection === null || this.connection.readyState === this.connection.CLOSED)
          this.connection = new WebSocket(this.connectionUrl);
        this.connection.onopen = function () {
          // this.send(JSON.stringify(chat));
          this.send(JSON.stringify({ ...chat, action: "REGISTER" }));
          this.send(JSON.stringify({ ...chat, action: "MARK-ONLINE-USER" }));
          chatStatus();
          clearTries();
          clearInterval(interval);
          console.log('Chat.onOpen:', this.readyState);
        };
        this.connection.onmessage = (ev) => {
          let data = JSON.parse(ev.data);
          this.mapMessage(data);
        };
        this.connection.onerror = function (err) {
          this.close();
          console.log('Chat Error', err);
        }
      } else {
        this.connectionTries = 7;
        clearInterval(interval);
      }
    }, 500)
  }

  mapMessage(data: any) {
    if (data.action === "MESSAGE" && data.id !== this.lastId) {
      if (location.href.indexOf('/chat') === -1) {
        this.eventService.broadcast('h-new-chat-message');
      }
      data = {
        fileData: data.fileData,
        fileName: data.fileName,
        fileType: data.fileType,
        id: data.id,
        message: data.message,
        timestamp: data.timestamp,
        wasItMe: data.wasItMe,
        wasRead: data.wasRead,
        writer: data.writer,
        reader: data.reader,
        chat: data.chat
      }
      this.lastId = data.id;
      this.eventService.broadcast('chat-new-message', data);
    }
    if (data.action === "MARK-ONLINE-USER") {
      if (this.activeUser.indexOf(data.user) === -1) {
        this.activeUser.push(data.user)
      }
      this.eventService.broadcast('mark-user-online', data.user);
    }
    if (data.action === "MARK-OFFLINE-USER") {
      const index = this.activeUser.indexOf(data.user);
      if (index > -1) {
        this.activeUser.splice(index, 1);
      }
      this.eventService.broadcast('mark-user-offline', data.user);
    }
    if (data.action === "MARK-ONLINE-USERS") {
      this.activeUser = data.users;
      this.eventService.broadcast('mark-users-online', data.users);
    }
  }

  clearChatPack() {
    this.chatPack.writer = "";
    this.chatPack.writerName = "";
    this.chatPack.writerPhoto = "";
    this.chatPack.writerEmail = "";
    this.chatPack.writerIsAgent = "";
    this.chatPack.writerIsMaster = "";
    this.chatPack.writerIsActive = "";
    this.chatPack.chat = "";
    this.chatPack.client = "";
    this.chatPack.property = "";
    this.chatPack.propertyReference = "";
    this.chatPack.propertyName = "";
    this.chatPack.reader = "";
    this.chatPack.readerName = "";
    this.chatPack.readerPhoto = "";
    this.chatPack.readerEmail = "";
    this.chatPack.readerIsAgent = "";
    this.chatPack.readerIsMaster = "";
    this.chatPack.readerIsActive = "";
    this.chatPack.entity = "";
  }

  closeConnection() {
    this.connectionTries = 0;
    if (this.connection.readyState !== WebSocket.CLOSED && this.connection.readyState !== this.connection.CONNECTING && this.connection.readyState !== this.connection.CLOSING) {
      this.chatPack.action = 'MARK-OFFLINE-USER';
      this.connection.send(JSON.stringify(this.chatPack));
      this.connection.close();
      this.connection.onclose = function (event) {
        if (!event.wasClean) {
          console.log('Chat.onClose.error!');
        }
        // location.reload();
        console.log('Chat.onClose.success!');
      }
    }
  }

  getReaders(id: any) {
    let entityId = this.idElement.value;

    this.verifyChatStatus();
    let data: any = {};
    data.action = "getReaders";
    data.entity = entityId;
    data.country = 620;
    data.language = this.languageService.currentLang;
    data.user = id;
    let formData = new FormData();
    formData.append('data', JSON.stringify(data));

    return this.http.post<any>(`${environment.v1Api}/get-chat.php`, formData);
  }

  getConversation(messageData: any) {
    let entityId = this.idElement.value;

    this.verifyChatStatus();
    this.initReaderChatPackage(messageData);
    let data: any = {};
    data.action = "getMessages";
    data.client = messageData.client;
    data.entity = entityId;
    data.property = messageData.property;
    data.reader = messageData.user;
    data.writer = messageData.userId;
    let formData = new FormData();
    formData.append('data', JSON.stringify(data));

    return this.http.post<any>(`${environment.v1Api}/get-chat.php`, formData).pipe(
      tap(data => {
        this.chatActive = data;
        this.send('SEEN');
      })
    )
  }

  initWriterChatPackage(user: any) {
    this.chatPack.writer = user.id;
    this.chatPack.writerName = user.name;
    this.chatPack.writerPhoto = user.photo;
    this.chatPack.writerEmail = user.email;
    this.chatPack.writerIsAgent = user.isAgent;
    this.chatPack.writerIsMaster = user.isMaster;
    this.chatPack.writerIsActive = user.isActive;
  }

  sendMessage(message: any) {
    this.chatPack.message = message;
    this.send('MESSAGE');
    this.send('SEEN');
  }

  markMessageAsRead() {
    this.send('SEEN');
  }

  initReaderChatPackage(data: any) {
    let entityId = this.idElement.value;

    this.chatPack.chat = data.chat;
    this.chatPack.client = data.client;
    this.chatPack.property = data.property;
    this.chatPack.propertyReference = data.propertyReference;
    this.chatPack.propertyName = data.propertyName;
    this.chatPack.reader = data.user;
    this.chatPack.readerName = data.name;
    this.chatPack.readerPhoto = data.photo;
    this.chatPack.readerEmail = data.email;
    this.chatPack.readerIsAgent = data.isAgent;
    this.chatPack.readerIsMaster = data.isMaster;
    this.chatPack.readerIsActive = data.isActive;
    this.chatPack.entity = data.entity ? data.entity : entityId;
  }

  verifyChatStatus() {
    if (this.connection === null || this.connection?.readyState !== this.connection?.OPEN && this.connection?.readyState !== this.connection?.CONNECTING) {
      try {
        this.closeConnection();
      } catch (err: any) {

      }
      this.eventService.broadcast('chat-disconnected');
    }
    if (this.connection?.readyState === this.connection?.CONNECTING) {
      this.eventService.broadcast('chat-connecting', true);
    }
  }

  send(action: any) {
    this.verifyChatStatus();
    this.chatPack.action = action;
    this.chatPack.timestamp = Math.floor(Date.now() / 1000);
    try {
      if (this.connection !== null && this.connection.readyState === this.connection.OPEN) {
        this.connection.send(JSON.stringify(this.chatPack));
      } else {
        this.verifyChatStatus();
      }
    } catch (err: any) {
      // console.log('catch', err);
    }
  }

  getId() {
    return this.idElement.value;
  }
}
