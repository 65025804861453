import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { EventService } from "./event.service";
import { DataminerV1Service } from "./services-v1/dataminer-v1.service";

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    private history: string[] = [];
    isChat: boolean = false;
    isReview: boolean = false;

    constructor(
        private router: Router,
        private location: Location,
        private eventService: EventService,
        private dataminerV1Service: DataminerV1Service
    ) { }

    startHistory() {
        const data = {
            screen: 'splash',
            buttonClicked: '',
            client: '',
            property: ''
        }
        this.dataminerV1Service.sendData(data).subscribe();
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (event.urlAfterRedirects.indexOf('agent-area/') === -1) {
                    if (event.urlAfterRedirects.indexOf('property/') === -1 || event.urlAfterRedirects.indexOf('property/') > -1 && this.history.length > 0 && this.history[this.history.length - 1].indexOf('property/') === -1)
                        this.history.push(event.urlAfterRedirects);
                }
            }
        })
    }

    getLastPage() {
        return this.history[this.history.length - 2];
    }

    goBack() {
        if (this.isChat) {
            this.eventService.broadcast('chat-mobile-back');
        } else {
            this.history.pop();
            if (this.history.length > 0 && !this.isReview) {
                this.router.navigateByUrl(this.history[this.history.length - 1]);
                // this.location.back()
            } else {
                this.router.navigateByUrl("/")
            }
        }
    }

    getUrl() {
        return this.router.url;
    }
}