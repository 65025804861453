import { HttpClient, HttpParams, HttpUrlEncodingCodec } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { delay, of, tap } from "rxjs";
import { environment } from "src/environments/environment";
import { Locations, PropertiesDetails, PropertyTypes } from "../data/properties.data";
import { HighlightPropertyModel } from "../models/highlights-property.model";
import { PropertyModel, PropertyReturn } from "../models/property.model";

@Injectable({
    providedIn: 'root'
})
export class PropertyService {
    properties = PropertiesDetails;
    propertiesTypes = PropertyTypes;
    locations = Locations;
    constructor(private http: HttpClient) { }

    getProperties(filters: any, page: any) {
        const params = new HttpParams({ encoder: new HttpUrlEncodingCodec() })
            .set('entityId', `${environment.entityId}`)
            .set('languageId', 1)
            .set('filters', JSON.stringify(filters))
            .set('page', page)
        return this.http.get<PropertyReturn>(`${environment.apiUrl}/properties`, { params });
        // return of({ properties: this.properties.slice(page, page + 8), total: this.properties.length });
    }

    getAgentProperties() {
        const properties = this.properties.filter(el => el.agent.name == 'Marco Silva');
        return of(properties);
    }

    getPropertyByReference(reference: string) {
        const params = new HttpParams()
            .set('entityId', environment.entityId)
            .set('languageId', 1)

        return this.http.get<PropertyModel>(`${environment.apiUrl}/properties/${reference}`, { params });
    }

    getHighlightsProperties() {
        const params = new HttpParams()
            .set('entityId', environment.entityId)
            .set('languageId', 1);

        return this.http.get<HighlightPropertyModel[]>(`${environment.apiUrl}/properties/highlights`, { params }).pipe(
            tap(properties => {

            }),
        )
    }

    getPropertiesTypes() {
        return of(this.propertiesTypes);
    }

    getLocations(search: any = null) {
        if (search === null)
            return of(this.locations);

        let filterLocation = this.locations.filter(el => {
            return el.name.trim().toLowerCase().includes(search);
        });
        return of(filterLocation);
    }

    getSavedProperties() {
        const featuredProperties: any = [];
        this.properties.map(el => {
            if (el.featured === true) {
                featuredProperties.push(el);
            };
        });
        return of(featuredProperties).pipe(
            // delay(2000)
        );
    }

    getPropertiesByReference(references: any[]) {
        let result: any = [];
        references.forEach(reference => {
            let property = this.properties.filter(el => el.reference == reference);
            result.push(property[0]);
        })
        return of(result);
    }

    getDataToSearch() {
        const businessType: any[] = [
            { id: 1, name: 'Sale' },
            { id: 2, name: 'Rent' }
        ];
        const propertiesType: any[] = [
            { id: 1, name: 'Apartment' },
            { id: 2, name: 'House' },
            { id: 3, name: 'Family House' },
            { id: 4, name: 'Farm' }
        ]
        return of({ 'businessType': businessType, 'propertyType': propertiesType });
    }

    encodeKey(key: string) {
        const encodedKey = new HttpUrlEncodingCodec().encodeKey(key);
        console.log(encodedKey);
        return encodedKey;
    }

    encodeValue(value: string) {
        return new HttpUrlEncodingCodec().encodeValue(value);
    }

}